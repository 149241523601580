<template>
  <div>
    <XNav :content="[{ t: 'pages.privacy_policy' }]"/>
    <div class="privacy-policy">
      <p>{{ $t('privacy_policy.last_updated') }}</p>
      <p>{{ $t('privacy_policy.intro.1') }}</p>
      <p>{{ $t('privacy_policy.intro.2') }}</p>
      <p class="subtitle">{{ $t('privacy_policy.interpretation_definitions.title') }}</p>
      <p class="subheader">{{ $t('privacy_policy.interpretation_definitions.interpretation.title') }}</p>
      <p>{{ $t('privacy_policy.interpretation_definitions.interpretation.1') }}</p>
      <p class="subheader">{{ $t('privacy_policy.interpretation_definitions.definitions.title') }}</p>
      <p>{{ $t('privacy_policy.interpretation_definitions.definitions.1') }}</p>
      <ul>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.account') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.account_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.affiliate') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.affilitate_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.company') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.company_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.cookies') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.cookies_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.country') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.country_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.device') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.device_def') }} </p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.personal_data') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.personal_data_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.service') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.service_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.service_provider') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.service_provider_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.usage_data') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.usage_data_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.website') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.website_def') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.interpretation_definitions.definitions.you') }}</strong> {{ $t('privacy_policy.interpretation_definitions.definitions.you_def') }}</p>
        </li>
      </ul>
      <p class="subtitle">{{ $t('privacy_policy.collecting_using_pers_data.title') }}</p>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.title') }}</p>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.personal_data') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.personal_data_def') }}</p>
      <ul>
        <li>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.1') }}</p>
        </li>
        <li>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.2') }}</p>
        </li>
        <li>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.types_of_data.3') }}</p>
        </li>
      </ul>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.usage_data.title') }} </p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.usage_data.1') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.usage_data.2') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.usage_data.3') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.usage_data.4') }}</p>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.title') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.1') }}</p>
      <ul>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.cookies') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.cookies_def') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.web_beacons') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.web_beacons_def') }}</li>
      </ul>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.2') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.3') }}</p>
      <ul>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.4') }}</strong></p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.4_1') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.4_2') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.4_3') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.5') }}</strong></p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.5_1') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.5_2') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.5_3') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.6') }}</strong></p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.6_1') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.6_2') }}</p>
          <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.6_3') }}</p>
        </li>
      </ul>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.tech_cookies.more_info') }}</p>
      <p class="subtitle">{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.title') }}</p>
        <p>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1') }}</p>
      <ul>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_1') }}</strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_1_') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_2') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_2_') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_3') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_3_') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_4') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_4_') }}</p>
        </li>
        <li>
        <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_5') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_5_') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_6') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_6_') }}.</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_7') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_7_') }}</p>
        </li>
        <li>
          <p><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_8') }}</strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.1_8_') }} </p>
        </li>
      </ul>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2') }}</p>
      <ul>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_1') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_1_') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_2') }}</strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_2_') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_3') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_3_') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_4') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_4_') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_5') }}</strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_5_') }}</li>
        <li><strong>{{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_6') }}</strong> {{ $t('privacy_policy.collecting_using_pers_data.use_pers_data.2_6_') }}</li>
      </ul>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.retention_pers_data.title') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.retention_pers_data.1') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.retention_pers_data.2') }}</p>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.transfer_pers_data.title') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.transfer_pers_data.1') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.transfer_pers_data.2') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.transfer_pers_data.3') }}</p>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.delete_pers_data.title') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.delete_pers_data.1') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.delete_pers_data.2') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.delete_pers_data.3') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.delete_pers_data.4') }}</p>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.title') }}</p>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.1') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.1_1') }} </p>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.2') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.2_1') }} </p>
      <p class="bold">{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1') }} </p>
      <ul>
        <li>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1_1') }}</li>
        <li>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1_2') }}</li>
        <li>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1_3') }}</li>
        <li>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1_4') }}</li>
        <li>{{ $t('privacy_policy.collecting_using_pers_data.disclosure_pers_data.3_1_5') }}</li>
      </ul>
      <p class="subheader">{{ $t('privacy_policy.collecting_using_pers_data.security_pers_data.title') }}</p>
      <p>{{ $t('privacy_policy.collecting_using_pers_data.security_pers_data.1') }}</p>
      <p class="subtitle">{{ $t('privacy_policy.children_privacy.title') }} </p>
      <p>{{ $t('privacy_policy.children_privacy.1') }} </p>
      <p>{{ $t('privacy_policy.children_privacy.2') }} </p>
      <p class="subtitle">{{ $t('privacy_policy.links_to_other_sites.title') }} </p>
      <p>{{ $t('privacy_policy.links_to_other_sites.1') }} </p>
      <p>{{ $t('privacy_policy.links_to_other_sites.2') }} </p>
      <p class="subtitle">{{ $t('privacy_policy.changes_to_privacy_policy.title') }} </p>
      <p>{{ $t('privacy_policy.changes_to_privacy_policy.1') }} </p>
      <p>{{ $t('privacy_policy.changes_to_privacy_policy.2') }} </p>
      <p>{{ $t('privacy_policy.changes_to_privacy_policy.3') }} </p>
      <p class="subtitle">{{ $t('privacy_policy.contact_us.title') }} </p>
      <p>{{ $t('privacy_policy.contact_us.1') }} </p>
      <ul>
        <li>{{ $t('privacy_policy.contact_us.1_1') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import XNav from "@/components/XNav.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    XNav,
  },
  metaInfo() {
    return {
      title: this.$t("seo_metadata.privacy_policy.title"),
      meta: [
        { vmid: "description", name: "description", content: this.$t("seo_metadata.privacy_policy.description") },
        { vmid: "keywords", name: "keywords", content: this.$t("seo_metadata.keywords") },
        { vmid: "og:title", property: "og:title", content: this.$t("seo_metadata.privacy_policy.title") },
        { vmid: "og:description", property: "og:description", content: this.$t("seo_metadata.privacy_policy.description") },
        { vmid: "og:url", property: "og:url", content: "https://kingsleague.ca/privacy-policy" },
        { vmid: "og:type", property: "og:type", content: "website"}
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";


  .subtitle {
    @extend %font-bold;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .subheader {
    @extend %font-bold;
    margin-top: 10px;
    font-size: 20px;
  }
  .bold {
    @extend %font-bold;
    margin-top: 10px;
    font-size: 18px;
  }

  p {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 16px;
  }

  strong {
    @extend %font-bold;
  }

  ul {
    margin-left: 20px;
  }

  li {
    margin-left: 20px;
  }


  @media only screen and (max-width: 600px) {
    .privacy-policy {
      width: 70vw;
      min-width: initial;
    }

    .bold {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    li {
      font-size: 14px;
    }
  }
</style>
